
import request from '@/utils/request';



class EventsApi {


    activity_list = (data) => {
        data.evt_type = 0
        return request({
            url: '/adm/brokerage/event/brokerage_events',
            method: 'post',
            data
        });	
    }
    

    training_list = (data) => {
        data.evt_type = 2
        return request({
            url: '/adm/brokerage/event/brokerage_events',
            method: 'post',
            data
        });	
    }

    save_training_event =(data) => {
        return request({
            url: '/adm/brokerage/event/setup_training_evt',
            method: 'post',
            data
        });	
    }

    save_activity_event = (data) => {
        return request({
            url: '/adm/brokerage/event/setup_activity_evt',
            method: 'post',
            data
        });	

        
    }

    delete_event =(data) => {
        return request({
            url: '/adm/brokerage/event/delete_training_evt',
            method: 'post',
            data
        });	
    }

}




let _api = null

const getEventsApi = () => {
    if (!_api) {
        _api = new EventsApi();
    }
    return _api;
}

export { getEventsApi };


