<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import {
  required,
} from "vuelidate/lib/validators";

import { getEventsApi } from '@/api/events'
/**
 * Project-overview component
 */
export default {
  page: {
    title: "Training",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  props: {
    training: {
      type: Object,
      default: () => ({
        ads_seq: 0
      })
    }
  },

  data() {
    return {
      title: "Training",
      items: [
        {
          text: "Training",
          href: '/events/training'
        },
        {
          text: "Edit",
          active: true,
        },
      ],

      dropzoneOptions: {
        url: "/AwsFileUpload/s3_upload_multi",
        thumbnailWidth: 150,
        maxFilesize: 10,
      },

      dropzoneOptions1: {
        url: "/AwsFileUpload/s3_upload_multi",
        thumbnailWidth: 150,
        maxFilesize: 200,
      },


    };
  },
  validations: {
    training: {
      start_time: { required },
      title: { required },

    },
  },
  components: {
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,
    flatPickr
  },

  methods: {
    save_training_event() {
      this.$v.$touch();
      if (this.$v.$invalid == false) {
        let data = {
          trainging: this.training
        }
        getEventsApi().save_training_event(data).then((res) => {
          if (res.errCode == 0) {
            this.$alertify.message("Save Success");
            this.$router.push({name : 'events-training'})
          } else {
            this.$alertify.error("Save Failed " + res.errCode);
          }
        })
      }
    },

    coverPhotoUploaded(ev, resp) {
      console.log("uploaded", ev.upload, ev, resp)
      if (resp[0].errCode == 200) {
        this.training.default_photo_url = resp[0].response.url
      }
    },
    videoUploaded(ev, resp) {
      (ev);
      if (resp[0].errCode == 200) {
        this.training.attachments = [{
          attachment_url: resp[0].response.url
        }]
      }
    }


  },




  mounted() {

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-0">
          <div class="card-body pb-4">


          </div>
          <!-- end card body -->
        </div>
        <!-- en card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="row">
                  <div class="col-lg-4">
                    <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions"
                      @vdropzone-success="coverPhotoUploaded">
                      <div class="dropzone-custom-content">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h4>Drop Cover photo here or click to upload Cover Photo.</h4>
                      </div>
                    </vue-dropzone>
                  </div>
                  <div class="col-lg-4">
                    <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions1"
                      @vdropzone-success="videoUploaded">
                      <div class="dropzone-custom-content">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h4>Drop video file here or click to upload video.</h4>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
                <div class="row">

                  <form>
                    <div>
                      <div class="mb-3 col-sm-6">
                        <label for="title" class="form-label">Title</label>
                        <input id="title" v-model="training.title" type="text" class="form-control"
                          placeholder="Enter Training Title" :class="{
                            'is-invalid': $v.training.title.$error,
                          }" />
                        <div v-if="$v.training.title.$error" class="invalid-feedback">
                          <span v-if="!$v.training.title.required">This value is required.</span>
                        </div>
                      </div>

                      <div class="mb-3  col-sm-6">
                        <label for="location" class="form-label">Location</label>
                        <input v-model="training.address" type="text" class="form-control" placeholder="Enter Location" />
                      </div>

                      <div class="mb-3">
                        <div class="row">
                          <div class="mb-3 col-sm-4">
                            <label for="start_time" class="form-label">Start Time</label>

                            <flat-pickr v-model="training.start_time" placeholder="Select End Time" class="form-control"
                              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" :class="{
                                'is-invalid': $v.training.start_time.$error,
                              }"></flat-pickr>
                            <div v-if="$v.training.start_time.$error" class="invalid-feedback">
                              <span v-if="!$v.training.start_time.required">This value is required.</span>
                            </div>
                          </div>
                          <div class="mb-3 col-sm-4">
                            <label for="end_time" class="form-label">End Time</label>
                            <flat-pickr v-model="training.end_time" placeholder="Select End Time"
                              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" class="form-control"></flat-pickr>
                          </div>
                        </div>
                      </div>

                      <div class="mb-3">
                        <label for="location" class="form-label">Recommend</label>
                        <div class="row">
                          <div class="mb-2 col-sm-1">
                            <input class="form-check-input" type="radio" value="1" v-model="training.ads_seq" />
                            <label class="form-check-label" for="formRadios1">
                              Yes
                            </label>
                          </div>
                          <!-- <div class="mb-2 col-sm-1">
                            <input class="form-check-input" type="radio" value="2" v-model="training.ads_seq" checked />
                            <label class="form-check-label" for="formRadios2">
                              Secondary
                            </label>
                          </div> -->
                          <div class="mb-2  col-sm-1">
                            <input class="form-check-input" type="radio" value="0" v-model="training.ads_seq" checked />
                            <label class="form-check-label" for="formRadios2">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                        


                      <div class="mb-3">
                        <label for="description" class="form-label">Description</label>
                        <textarea class="form-control" v-model="training.description" id="description" rows="3"
                          placeholder="Enter Description..."></textarea>
                      </div>

                    </div>
                  </form>
                </div>
                <!-- wizard-tab -->



                <div class="d-flex align-items-start gap-3 mt-4">
                  <button type="button" class="btn btn-primary w-sm ms-auto" @click="save_training_event()">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
